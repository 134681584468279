<template>
  <div class="col-12">
    <div
      class="share_div2 d-flex p-3 text-center col-12 justify-content-around"
    >
      <span>{{ title_val }}</span>
    </div>

    <div class="row my-2">
      <div class="col-xl-6 col-12">
        <span class="px-2 col-12 fw-bolder mb-2">{{
          $t("main_share_text1")
        }}</span>
        <div
          class="img_view col-12 p-2 mx-auto my-2 border_radius_26"
          :style="{ 'background-image': `url(${title_img})` }"
          ref="imgView"
        >
          <div class="img_code">
            <!-- 二维码 -->
            <vue-qrcode :value="shareurl" />
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-12 mt-xl-0 mt-2">
        <span class="px-2 col-12 fw-bolder">{{ $t("main_share_text2") }}</span>
        <div class="col-12 p-2 sh_border my-2 border_radius_26">
          {{ title_text }}
        </div>
      </div>
    </div>

    <p class="px-2 col-12 fw-bolder mt-3">{{ $t("main_share_text3") }}</p>
    <div class="col-12 d-flex" @click="handleImg()">
      <div class="sgare_card" @click="share_url('whatsapp')">
        <img src="@/assets/img/share_app/whatsapp.png" alt="" />
      </div>
      <div class="sgare_card" @click="share_url('telegram')">
        <img src="@/assets/img/share_app/telegram.png" alt="" />
      </div>
      <div class="sgare_card" @click="share_url()">
        <img src="@/assets/img/share_app/instagram.png" alt="" />
      </div>
      <div class="sgare_card" @click="share_url('line')">
        <img src="@/assets/img/share_app/line.png" alt="" />
      </div>
      <div class="sgare_card" @click="share_url()">
        <img src="@/assets/img/share_app/twitter.png" alt="" />
      </div>
      <div class="sgare_card" @click="share_url('messenger')">
        <img src="@/assets/img/share_app/messenger.png" alt="" />
      </div>
      <div class="sgare_card" @click="share_url()">
        <img src="@/assets/img/share_app/tiktok.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import VueQrcode from "vue-qrcode";
import M2canvas from "html2canvas";
import { saveAs } from "file-saver";
export default {
  props: {
    title: String,
    title_img: String,
    title_text: String,
    shareurl: String,
  },
  components: { VueQrcode },
  data() {
    return {
      title_val:
        this.title == 1
          ? this.$t("main_share_sub1")
          : this.title == 2
          ? this.$t("main_share_sub2")
          : this.$t("main_share_sub3"),
      cvURL: "",
      flag_download: true,
    };
  },
  methods: {
    handleImg() {
      if (this.flag_download) {
        this.Copy();
        //获取页面元素节点
        let imgTag = this.$refs["imgView"], //生成canvas前的参数配置，详细配置信息请参考官方文档
          params = { useCORS: true }; // 生成canvas图片文件流信息 with: 450, height: 450,
        M2canvas(imgTag, params).then((canvas) => {
          // 生成用于展示的数据地址
          this.flag_download = false;
          this.cvURL = canvas.toDataURL(); // 显示弹窗并展示生成的图片文件
          saveAs(this.cvURL, "WIN365"); // 保存弹窗中生成的图片
        });
      }
    },
    Copy: function () {
      var content = this.title_text;
      this.$copyText(content).then();
    },
    share_url: function (e) {
      if (e != null && e != "") {
        switch (e) {
          case "whatsapp":
            window.open("whatsapp://send?text=" + this.title_text, "_blank");
            break;
          case "telegram":
            window.open(
              "https://t.me/share/url?url=" + this.title_text,
              "_blank"
            );
            break;
          case "line":
            window.open(
              "https://line.me/R/share?text=" + this.title_text,
              "_blank"
            );
            break;
          case "messenger":
            window.open(
              "fb-messenger://share?link=" + this.title_text,
              "_blank"
            );
            break;
        }
      } else {
        if (navigator.share) {
          navigator
            .share({ url: this.title_text, title: this.title_text, text: "" })
            .then(() => console.log("success"))
            .catch((error) => console.log("error", error));
        }
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<template>
  <div id="sport_all" class="sport_lobby mt-2">
    <div class="row mx-auto sub_game_sport">
      <div
        class="game_item_2 game_item top_game_left"
        @click="game('web', 'sport', '', 'SB', 'sport')"
      >
        <img
          class="img-right"
          src="@/assets/img/sport/imgpsh_fullsize_anim_4.png"
          alt=""
        />
        <div class="top_game_tit">
          <p class="tit_title">SABA</p>
          <p class="tit_word">
            Unique Betting Experience Various Playing Methods
          </p>
        </div>
        <div class="buttons_box"></div>
      </div>

      <div class="live_casino tab-inner">
        <div class="live_casino_card">
          <div class="game_slider_card">
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=3',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_PREMIER.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=2;sportid=2;leaguekey=56038',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_NBA.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=43',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_LALIGA.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=8',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_LIGUE1.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=5',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_BUNDESLIGA.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=1;sportid=1;leaguekey=4',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_SERIEA.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=8;sportid=8;leaguekey=127094',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_MLB.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  'WebSkinType=3;menutype=0;act=3;sportid=3;leaguekey=127105',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_NFL.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div>
            <!-- <div
              class="filter_card"
              @click="
                game(
                  'web',
                  'sport',
                  '43',
                  'SB',
                  'sport'
                )
              "
            >
              <div
                class="filter_card1"
                v-lazy:background-image="
                  require('@/assets/img/sport/sport_esport.png')
                "
              ></div>
              <div class="mask position-absolute top-0 start-0"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import "@/assets/style/sport.scss";
import newWindow from "./iframe.vue";
export default {
  data() {
    return {
      code: Cookies.get("code"),
      css_list: "position-relative",
      newWindowurl: "",
    };
  },
  methods: {
    game: function (device, mode, id, type, kind) {
      if (this.code == null || this.code == "") {
        this.$router.push("Login");
        return;
      } else {
        if (id == "Coming Soon") {
          alert(this.$t("text_coming"));
        } else if (id == "GameLobby") {
          localStorage.setItem("g", type);
          this.$router.push({ name: "Game" });
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB" || type.toUpperCase() == "SEXYB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            this.newWindowurl = res;
          });
        }
      }
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
  watch: {
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$emit("watch_newWindowurl", "close");
      }
    },
  },
  components: {
    newWindow,
  },
  computed: {},
  mounted() {},
  created() {},
};
</script>

<template>
  <div
    class="wc-PageView_ContentContainer wc-LegacyPage_ContentContainerBankBalance wc-ModulePageChangePageData_ContentContainer"
    style="height: 105vh"
  >
    <div
      class="wc-PageView_ContentContainer wc-OpenAccountLegacyPage_ContentContainer"
    >
      <div>
        <!-- 存款頁面 -->
        <div>
          <div>
            <div id="credit_pa">
              <div class="payment_banner">
                <div class="payment_container">
                  <div class="payment-method-selector-container">
                    <swiper
                      :slidesPerView="3"
                      :spaceBetween="15"
                      :modules="modules"
                      :navigation="swiper_options.navigation"
                      :breakpoints="swiper_options.breakpoints"
                    >
                      <swiper-slide
                        v-for="(l, index) in list"
                        :key="index"
                        style="text-align:center; margin-right: 0px;!important"
                      >
                        <div
                          class="payment-method"
                          :class="
                            index == this.ChooseIndex ? 'payment_selected' : ''
                          "
                          @click="onChange(index)"
                        >
                          <item :cname="l.cname" :mpkidx="l.mpkidx" />
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                </div>
              </div>
              <div
                class="usdt_page"
                :class="this.ChooseName != '银行转帐' ? '' : 'Hidden'"
              >
                <div
                  class="deposit_amount w-100 bg-white d-flex"
                  style="border: 2px solid #b5b5b5"
                  @click="click_input_payment()"
                >
                  <input
                    type="text"
                    :placeholder="this.$t('deposit_amt')"
                    v-model="payment"
                    :class="flag_pro ? 'text-end' : 'text-center'"
                    id="ref_payment"
                  />
                  <span
                    v-show="flag_pro"
                    class="flexiField_AditionalInfo fw-bolder"
                    >+{{ fee }}
                  </span>
                  <span class="deposit_cur">
                    {{ $t("cur") }}
                  </span>
                </div>
                <p class="mt-2 mb-1" v-show="flag_pay_error" style="color: red">
                  {{ $t("error_deposit_tips") }}
                </p>
                <div v-show="this.feerate != 0" class="form-check mt-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckIndeterminate"
                    v-model="checkchoice"
                  />
                  <label class="form-check-label" for="flexCheckIndeterminate">
                    {{ title }}
                  </label>
                </div>

                <p class="mt-2 mb-1">{{ $t("deposit_min") }}：{{ Min }}</p>
                <p class="">{{ $t("deposit_max") }}：{{ Max }}</p>
                <p style="color: red;">VUI LÒNG CHỌN [ Quick] ĐỂ NẠP 50 000</p>
                <div class="col-12 text-center">
                  <button
                    type="submit"
                    class="border-0 text-white bg_green_btn p-2 w-50 mt-3"
                    @click="deposit_tmp"
                    :disabled="flag_pay_error"
                    :style="
                      flag_pay_error
                        ? 'cursor: no-drop;background-color: #CCC;'
                        : ''
                    "
                  >
                    {{ $t("deposit_btn2") }}
                  </button>
                </div>
                <p class="usdt_page_word">
                  {{ $t("deposit_str4") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <newWindow
      :URL="newWindowurl"
      :PAGE="'Deposit'"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import { reactive } from "vue";
import Cookies from "js-cookie";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import newWindow from "./iframe.vue";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
import item from "./Home/depositItem";

export default {
  name: "Home",
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 2,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 3,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    item,
    newWindow,
  },
  computed: {},
  data() {
    return {
      list: [],
      payment: 0,
      ChooseIndex: 0,
      ChooseName: "",
      capital: "",
      Max: 0,
      Min: 0,
      feerate: 0,
      feemax: 0,
      feemin: 0,
      fee: 0,
      mpkidx: "",
      newWindowurl: "",
      cname: "",
      cardno: "",
      flag_pro: false,
      checkchoice: [],
      title: this.$t("text_coll_deposit_time1"),
      is_first: "N",
      flag_pay_error: false,
    };
  },
  methods: {
    Choose: function () {
      let param = {
        action: "choose",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var str = "";
        try {
          this.list = res["data"].filter((v) => {
            if (!str.includes(v.cname)) {
              str = v.cname + ",";
              return v;
            }
          });
          this.onChange(0);
        } catch {
          this.$MSG.API_popmsg(res, "wallet", "warning");
        }
      });
    },
    deposit_tmp: function () {
      let max, min, money;
      max = parseInt(this.Max);
      min = parseInt(this.Min);
      money = parseInt(this.payment);
      console.log(this.mpkidx);
      if (money >= min && money <= max) {
        let param = {
          action: "deposit_tmp",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            amount: this.payment,
            mpkidx: this.mpkidx,
            capital: this.capital,
            cname: this.cname,
            cardno: this.cardno,
            checkchoice: this.flag_pro,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.newWindowurl = res.msg;
          } else {
            this.$MSG.API_popmsg(res.msg, "", "warning");
          }
        });
      } else {
        this.$MSG.API_popmsg(
          this.$t("deposit_min_tips") +
            "  " +
            this.Min +
            "," +
            this.$t("deposit_max_tips") +
            "  " +
            this.Max +
            ".",
          "",
          "error"
        );
      }
    },
    onChange: function (e) {
      this.ChooseIndex = e;
      var c = this.list[e];
      this.ChooseName = c.cname;
      this.mpkidx = c.mpkidx;
      this.capital = c.limit;
      this.feerate = c.feerate;
      this.feemax = c.feemax;
      this.feemin = c.feemin;
      this.is_first = c.is_first;
      if (this.is_first == "N") this.title = this.$t("text_coll_deposit_time2");
      if (parseFloat(this.payment) >= parseFloat(this.feemin)) {
        this.fee =
          this.feerate * this.payment * 0.01 > this.feemax
            ? this.feemax
            : this.feerate * this.payment * 0.01;
      } else this.fee = 0;
      this.Min = parseInt(this.capital.split(",")[0]);
      this.Max = parseInt(this.capital.split(",")[1]);
    },
    click_input_payment: function () {
      document.getElementById("ref_payment").focus();
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
    home_newWindowurl(e) {
      if (e == "close") {
        this.$refs.getbalance_home.getBalance();
        this.$refs.newmesstop.getnewmess();
      }
    },
  },
  watch: {
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
      if (parseFloat(this.payment) >= parseFloat(this.feemin)) {
        this.fee =
          this.feerate * this.payment * 0.01 > this.feemax
            ? this.feemax
            : this.feerate * this.payment * 0.01;
      } else this.fee = 0;
    },
    checkchoice() {
      if (this.checkchoice.length > 0) this.flag_pro = true;
      else this.flag_pro = false;
    },
  },
  created() {
    this.Choose();
  },
};
</script>
<style>
#credit_pa .swiper-button-prev,
#credit_pa .swiper-button-next {
  display: block !important;
}

#credit_pa .swiper-button-next:after,
#credit_pa .swiper-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 18px;
  color: #000;
}

#credit_pa .swiper-button-prev:after,
#credit_pa .swiper-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 18px;
  color: #000;
}
</style>
